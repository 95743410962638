// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl } from '../../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'


export const getPushNotificationList = createAsyncThunk(
    'notification/getPushNotificationList',
    async (params) => {
        const response = await axios.get(baseUrl() + '/fcm/getAll', { params })
        return {
            totalPages: response.data.totalCount,
            params: response.data.params,
            data: response.data.data
        }
    }
)

export const getUserContactList = createAsyncThunk('user/getUserContactList', async () => {
    const response = await axios.get(baseUrl() + `/enduser/contacts`)

    return {
        data: response.data.data
    }
})

export const getClientContactList = createAsyncThunk('user/getClientContactList', async () => {
    const response = await axios.get(baseUrl() + `/company/getcontacts`)

    return {
        data: response.data.data
    }
})
export const getWhatsAppNotificationList = createAsyncThunk(
    'notification/getWhatsAppNotificationList',
    async (params) => {
        const response = await axios.get(baseUrl() + '/fcm/getmeta', { params })
        return {
            totalPages: response.data.totalCount,
            params: response.data.params,
            data: response.data.data
        }
    }
)

export const getTextMessageList = createAsyncThunk(
    'notification/getTextMessageList',
    async (params) => {
        const response = await axios.get(baseUrl() + '/fcm/gettextmsg', { params })
        return {
            totalPages: response.data.totalCount,
            params: response.data.params,
            data: response.data.data
        }
    }
)


export const sendNotification = createAsyncThunk(
    'notification/sendNotification',
    async ({ payload, params }, { dispatch, getState }) => {
        console.log("notify", params, payload)
        try {
            await axios.post(baseUrl() + '/fcm/send', payload)
            toast.success('Notification Send to all successfully')
            dispatch(setStatus("0"))
            await dispatch(getPushNotificationList({ params: params }))
            return true
        } catch (error) {
            toast.error(error?.response?.data.error)

            return false
        }
    }
)

export const sendUserNotification = createAsyncThunk(
    'notification/sendUserNotification',
    async ({ payload, params }, { dispatch, getState }) => {
        console.log("notify", params, payload)
        try {
            await axios.post(baseUrl() + '/fcm/senduser', payload)
            toast.success('Notification Send to Users successfully')
            dispatch(setStatus("0"))
            await dispatch(getPushNotificationList({ ...params }))
            return true
        } catch (error) {
            toast.error(error?.response?.data.error)

            return false
        }
    }
)

export const sendClientNotification = createAsyncThunk(
    'notification/sendClientNotification',
    async ({ payload, params }, { dispatch, getState }) => {
        try {
            await axios.post(baseUrl() + '/fcm/sendclient', payload)
            toast.success('Notification Send to Owners successfully')
            dispatch(setStatus("0"))
            await dispatch(getPushNotificationList({ params: params }))
            return true
        } catch (error) {
            toast.error(error?.response?.data.error)

            return false
        }
    }
)

export const sendWhatsAppNotification = createAsyncThunk(
    'notification/sendWhatsAppNotification',
    async ({ payload, params }, { dispatch, getState }) => {
        try {
            await axios.post(baseUrl() + '/fcm/sendmeta', payload)
            toast.success('WhatsApp Notification Send successfully')
            dispatch(setStatus("1"))
            dispatch(getWhatsAppNotificationList({ params: params }))
            return true
        } catch (error) {
            toast.error(error?.response?.data.error)
            return false
        }
    }
)

export const sendTextMessage = createAsyncThunk(
    'notification/sendTextMessage',
    async ({ payload, params }, { dispatch, getState }) => {
        try {
            await axios.post(baseUrl() + '/fcm/sendtext', payload)
            toast.success('Text Message Send successfully')
            dispatch(setStatus("2"))
            dispatch(getTextMessageList({ params: params }))
            return true
        } catch (error) {
            toast.error(error?.response?.data.error)

            return false
        }
    }
)

export const deleteNotification = createAsyncThunk(
    'notification/deleteNotification',
    async ({ id }, { dispatch, getState }) => {
        try {
            await axios.delete(baseUrl() + `/notification/${id}`),
                toast.success('Location Deleted successfully')
            await dispatch(getPushNotificationList())
            return true
        } catch (error) {
            toast.error(error?.response?.data?.result)
            return false
        }
    }
)

export const notification = createSlice({
    name: 'notification',
    initialState: {
        notificationList: [],
        selectNotification: [],
        allNotificationList: [],
        userContactsList: [],
        clientContactList: [],
        reload: false,
        loader: false,
        rowsPerPage: 10,
        currentPage: 1,
        totalPages: 0,
        selected: null,
        status: '0',
        loading: false,
        clearFilter: false,
        selected: null,
        params: {
            pageNo: 1,
            pageSize: 10
        }

    },
    reducers: {
        selectNotification: (state, action) => {
            if (action.payload === null) {
                state.selected = null
            } else {
                state.selected = action.payload
            }
        },
        selectCurrentPage: (state, action) => {
            if (action.payload === null) {
                state.currentPage = null
            } else {
                state.currentPage = action.payload
            }
        },
        selectRowsPerPage: (state, action) => {
            if (action.payload === null) {
                state.rowsPerPage = null
            } else {
                state.rowsPerPage = action.payload
            }
        },
        selectClearFilter: (state, action) => {
            state.clearFilter = action.payload
        },
        setStatus: (state, action) => {
            state.status = action.payload
        },
        setLoader: (state) => {
            state.loader = !state.loader
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        }

    },

    extraReducers: {
        [sendNotification.pending]: (state) => {
            state.loader = true
        },
        [sendNotification.fulfilled]: (state, action) => {
            state.reload = !state.reload
            state.loader = false
        },
        [sendNotification.rejected]: (state) => {
            state.loader = false
        },
        [getPushNotificationList.pending]: (state) => {
            state.loader = true
        },
        [getPushNotificationList.fulfilled]: (state, action) => {
            state.notificationList = action.payload.data
            state.totalPages = action.payload.totalPages
            state.loader = false
        },
        [getPushNotificationList.rejected]: (state) => {
            state.loader = false
        },
        [getUserContactList.fulfilled]: (state, action) => {
            state.userContactsList = action.payload.data
        },
        [getClientContactList.fulfilled]: (state, action) => {
            state.clientContactList = action.payload.data
        },
        [getWhatsAppNotificationList.pending]: (state) => {
            state.loader = true
        },
        [getWhatsAppNotificationList.fulfilled]: (state, action) => {
            state.notificationList = action.payload.data
            state.totalPages = action.payload.totalPages
            state.loader = false
        },
        [getWhatsAppNotificationList.rejected]: (state) => {
            state.loader = false
        },
        [getTextMessageList.fulfilled]: (state, action) => {
            state.notificationList = action.payload.data
            state.totalPages = action.payload.totalPages
            state.loader = false
        },
    }
})

export const { selectNotification, selectCurrentPage,
    selectRowsPerPage,
    selectClearFilter,
    setStatus,
    setLoader,
    setLoading } = notification.actions

export default notification.reducer
